/**
 * @created by Zheko Hristov on 19/10/2021
 *
 * Facebook scripts
 **/

/**
 * Class hidden should be added to the fb share button
 *
 **/

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 19/10/2021
         * @updated by Zheko Hristov on 19/10/2021
         *
         * Facebook initialize
         **/
       // console.log(window.fbInitialized);
        if (window.fbInitialized !== undefined && window.fbInitialized == true) {
            initializeShareButton();
        }

        initializeFacebook();

        function initializeFacebook() {
            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = '//connect.facebook.net/bg_BG/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        }

        /**
         * @created by Zheko Hristov on 19/10/2021
         * @updated by Zheko Hristov on 20/10/2021
         *
         * FB.init() scripts
         **/

        window.fbAsyncInit = function () {
            FB.init({
                // appId: '{your-app-id}',
                // test appId for localhost
                appId: '291874809421481',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v12.0',
            });

            FB.Event.subscribe('xfbml.render', fbSDKInitialized);
        };

        function fbSDKInitialized() {
            window.fbInitialized = true;
            initializeShareButton();
        }

        function initializeShareButton() {
            /**
             * @created by Zheko Hristov on 19/10/2021
             * @updated by Zheko Hristov on 20/10/2021
             *
             * Share page with FB.ui() scripts
             **/

            const fbShareButton = document.querySelector('.js-fb-share-button');

            if (fbShareButton) {
                fbShareButton.classList.remove('hidden');
                const shareUrl = fbShareButton.dataset.url || window.location.href;
                fbShareButton.addEventListener('click', function () {
                    FB.ui(
                        {
                            method: 'share',
                            href: shareUrl,
                        },
                        function (response) {
                            console.log(response);
                        }
                    );
                });
            }
        }
    })();
});
