import Alerts from './alerts-scripts';

/**
 * @created by Zheko Hristov on 17/08/2021
 *
 * Order scripts
 **/

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';
        /**
         * @created by Zheko Hristov on 17/08/2021
         * @last-updated by Zheko Hristov on 12/08/2024
         *
         * Form order scripts
         **/
        const orderForm = document.querySelector('#new-order');
        const addToCartButton = document.querySelector('.js-add-to-cart');
        const productFormOrderContainer = document.querySelector(
            '.product-details__container--order'
        );
        const radioButtonsContiner = document.querySelector(
            '.form-input__container--right.radio-buttons__container'
        );

        // show order form container function
        const showOrderContainer = function showOrderContainer(showTimeOutValue) {
            productFormOrderContainer.classList.add('showed');

            setTimeout(function () {
                productFormOrderContainer.scrollIntoView();
            }, showTimeOutValue);
        };

        const bindSpamProtection = function bindSpamProtection() {
            const colorSelect = document.querySelector('.js-order-color-select');
            if (colorSelect !== undefined) {
                colorSelect.value = 'green';
            }
        };

        if (addToCartButton) {
            bindSpamProtection();

            // // if after the form is submitted returns errors on load show the form container
            // if (document.querySelector('.alerts__container--inner.error')) {
            //     showOrderContainer(1000);
            // }

            // if after the form is submitted returns errors on load show the form container
            if (orderForm.classList.contains('show-form')) {
                showOrderContainer(1000);
            }

            addToCartButton.addEventListener('click', function () {
                showOrderContainer(250);
            });

            
            // show/hide address containers and disable/enable textarea elements inside based on radio button change event
            // radioButtonsContiner.addEventListener('change', function (e) {
            //     let target = e.target;
            //     const formContainerAddressOptionContainer = document.querySelector(
            //         '.form-input__container--outer.delivery__container--address'
            //     );
            //     const formContainerEkontOfficeOptionContainer = document.querySelector(
            //         '.form-input__container--outer.delivery__container--ekont-office'
            //     );

            //     switch (target.id) {
            //         case 'user_delivery_address':
            //             formContainerAddressOptionContainer.classList.remove('hidden');
            //             formContainerAddressOptionContainer.querySelector(
            //                 'textarea'
            //             ).disabled = false;

            //             formContainerEkontOfficeOptionContainer.classList.add('hidden');
            //             formContainerEkontOfficeOptionContainer.querySelector(
            //                 'textarea'
            //             ).disabled = true;
            //             break;
            //         case 'user_delivery_office_ekont':
            //             formContainerAddressOptionContainer.classList.add('hidden');
            //             formContainerAddressOptionContainer.querySelector(
            //                 'textarea'
            //             ).disabled = true;

            //             formContainerEkontOfficeOptionContainer.classList.remove('hidden');
            //             formContainerEkontOfficeOptionContainer.querySelector(
            //                 'textarea'
            //             ).disabled = false;
            //             break;
            //         default:
            //             console.log('not found radio id attribute');
            //     }
            // });
        }
    })();
});
