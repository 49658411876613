/**
 * @created by Zheko Hristov on 17/08/2021
 *
 * Main site scripts
 **/

document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 18/03/2022
         * @last-updated by Zheko Hristov on 18/03/2022
         *
         * Close the mobile menu if it is not closed after browser back button clicks
         **/

        if ($('.mobile-menu__container--icons .fa-times').hasClass('showed')) {
            $(
                '.mobile-menu__container--icons .fa-times, .categories__container--list '
            ).removeClass('showed');
            $('.mobile-menu__container--icons .fa-bars').addClass('showed');
        }
    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 22/10/2021
         * @last-updated by Zheko Hristov on 22/10/2021
         *
         * iOS input zoom disable
         **/

        // if the devices are iPad|iPhone|iPod, execute the function only for them,
        // because this function will stop the default zooming on Androd devices
        if (checkIfIsIOS()) {
            addMaximumScaleToMetaViewport();
        }

        // funcion for check the device
        function checkIfIsIOS() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }

        // function fo setting maximum-scale=1.0 to meta viewport
        // prevent iOS input zoom and not pevent the default zooming
        // revent Android default zooming
        function addMaximumScaleToMetaViewport() {
            const el = document.querySelector('meta[name=viewport]');
            if (el !== null) {
                let content = el.getAttribute('content');
                let re = /maximum\-scale=[0-9\.]+/g;
                if (re.test(content)) {
                    content = content.replace(re, 'maximum-scale=1.0');
                } else {
                    content = [content, 'maximum-scale=1.0'].join(', ');
                }
                el.setAttribute('content', content);
            }
        }

        /**
         * @created by Zheko Hristov on 15/10/2021
         * @last-updated by Zheko Hristov on 15/10/2021
         *
         * Main container min-height calc after adding bottom footer container
         **/

        // trigger on load
        mainContainerOuterMinHeightCalc();

        // resize event
        window.addEventListener('resize', function () {
            // trigger on resize
            mainContainerOuterMinHeightCalc();
            // do stuff here
        });

        function mainContainerOuterMinHeightCalc() {
            const mainContainer = document.querySelector('.main__container--outer');
            const bottomFooterContainer = document.querySelector('.footer__container--bottom');

            const windowHeight = window.innerHeight;
            const bottomFooterContainerHeight = bottomFooterContainer.clientHeight;
            const calculatedHeight = windowHeight - bottomFooterContainerHeight;

            mainContainer.style.minHeight = calculatedHeight + 'px';
        }

        /**
         * @created by Zheko Hristov on 10/08/2021
         * @last-updated by Zheko Hristov on 13/08/2021
         *
         * Mobile menu scripts
         **/

        const openMobileMenuIcon = document.querySelector(
            '.mobile-menu__container--icons .fa-bars'
        );
        const closeMobileMenuIcon = document.querySelector(
            '.mobile-menu__container--icons .fa-times'
        );
        const categoriesContainer = document.querySelector('.categories__container--list');

        // open mobile menu
        openMobileMenuIcon.addEventListener('click', function () {
            this.classList.remove('showed');
            closeMobileMenuIcon.classList.add('showed');
            categoriesContainer.classList.add('showed');
        });

        // close mobile menu
        closeMobileMenuIcon.addEventListener('click', function () {
            this.classList.remove('showed');
            openMobileMenuIcon.classList.add('showed');
            categoriesContainer.classList.remove('showed');
        });

        /**
         * @created by Zheko Hristov on 16/08/2021
         * @last-updated by Zheko Hristov on 17/03/2022
         *
         * Product details show full image container
         * and add zoom in and out functionality
         **/

        const detailsProductImageContainer = document.querySelector(
            '.product__container--image-outer'
        );

        if (detailsProductImageContainer) {
            const detailsProductFullImageContainer = document.querySelector(
                '.product-details__container--full-image'
            );
            const detailsProductFullImageImage = detailsProductFullImageContainer.querySelector(
                '.product__container--image-inner'
            );
            const detailsProductFullImageCloseIcon = document.querySelector(
                '.product-details__container--full-image .fa-times'
            );
            const detailsProductFullImageZoomInOutIcon = document.querySelector(
                '.product-details__container--full-image .fa-search'
            );

            // zoom in and out function
            const fullImageZoomInOut = function fullImageZoomInOut() {
                detailsProductFullImageImage.classList.toggle('zoomed');
            };

            // show full image container
            let secondaryActiveImageIndex;
            detailsProductImageContainer.addEventListener('click', function () {
                secondaryActiveImageIndex = $('.products__container--single.active').index();
                updateDetailsProductFullscreenImage(secondaryActiveImageIndex);
                // zoom the image with some delay
                detailsProductFullImageContainer.classList.add('showed');
                setTimeout(fullImageZoomInOut, 125);
            });

            // hide full image container
            detailsProductFullImageCloseIcon.addEventListener('click', function () {
                detailsProductFullImageContainer.classList.remove('showed');
                // zoom out the image
                detailsProductFullImageImage.classList.remove('zoomed');
            });

            // zoom in and out on click zoom icon
            detailsProductFullImageZoomInOutIcon.addEventListener('click', fullImageZoomInOut);
            // zoom in and out on click image
            detailsProductFullImageImage.addEventListener('click', fullImageZoomInOut);

            // change main image
            const mainImageContainerInner = $(
                '.product__container--image-outer .product__container--image-inner'
            );

            const secondaryImageContainer = $(
                '.details-product__container--secondary-images .product__container--image-inner'
            );

            // create array with image source values
            const secondaryImagesSources = [];
            secondaryImageContainer.each(function () {
                secondaryImagesSources.push($(this).attr('data-image-src'));
            });

            // update main background image container with image source of the active container
            $('.details-product__container--secondary-images').on(
                'click',
                '.product__container--image-inner',
                function () {
                    const thisImageContainer = $(this);
                    const thisImageSource = thisImageContainer.attr('data-image-src');

                    secondaryImageContainer
                        .parent('.products__container--single')
                        .removeClass('active');
                    thisImageContainer.parent('.products__container--single').addClass('active');

                    changeBackgroundImageWithAnimation(mainImageContainerInner, thisImageSource);
                }
            );

            // show previous image in images gallery mode
            $(detailsProductFullImageContainer)
                .find('.fa-chevron-left')
                .on('click', function () {
                    if (secondaryImagesSources.length > 1) {
                        if (secondaryActiveImageIndex === 0) {
                            secondaryActiveImageIndex = secondaryImagesSources.length - 1;
                        } else {
                            secondaryActiveImageIndex--;
                        }

                        updateDetailsProductFullscreenImage(secondaryActiveImageIndex);
                    }
                });

            // show next image in images gallery mode
            $(detailsProductFullImageContainer)
                .find('.fa-chevron-right')
                .on('click', function () {
                    if (secondaryImagesSources.length > 1) {
                        if (secondaryActiveImageIndex === secondaryImagesSources.length - 1) {
                            secondaryActiveImageIndex = 0;
                        } else {
                            secondaryActiveImageIndex++;
                        }

                        updateDetailsProductFullscreenImage(secondaryActiveImageIndex);
                    }
                });

            // update full image mode background image function
            function updateDetailsProductFullscreenImage(index) {
                changeBackgroundImageWithAnimation(
                    $(detailsProductFullImageImage),
                    index === -1
                        ? mainImageContainerInner.attr('data-image-src')
                        : secondaryImagesSources[index]
                );
            }

            // change background image with animation function
            function changeBackgroundImageWithAnimation(container, source) {
                container.css({
                    opacity: '0',
                });
                setTimeout(function () {
                    container.css({
                        'background-image': 'url("' + source + '")',
                    });
                    setTimeout(function () {
                        container.css({ opacity: '1' });
                    }, 100);
                }, 100);
            }
        }

        /**
         * @created by Zheko Hristov on 10/08/2021
         * @last-updated by Zheko Hristov on 13/08/2021
         *
         * Contact us page show/hide offices
         **/

        const officeNameElements = document.querySelectorAll('.single-office__text--name');
        const officeNameElementsLength = officeNameElements.length;

        for (let i = 0; i < officeNameElementsLength; i++) {
            officeNameElements[i].addEventListener('click', function () {
                document
                    .querySelector('.single-office__text--name.selected')
                    .classList.remove('selected');
                document
                    .querySelector('.single-office__text--name:nth-of-type(' + (i + 1) + ')')
                    .classList.add('selected');

                document
                    .querySelector('.single-office__container--map.selected')
                    .classList.remove('selected');
                document
                    .querySelector('.single-office__container--map:nth-of-type(' + (i + 1) + ')')
                    .classList.add('selected');

                document
                    .querySelector('.single-office__container--details.selected')
                    .classList.remove('selected');
                document
                    .querySelector(
                        '.single-office__container--details:nth-of-type(' + (i + 1) + ')'
                    )
                    .classList.add('selected');
            });
        }

        /**
         * @created by Zheko Hristov on 25/10/2021
         * @last-updated by Zheko Hristov on 25/10/2021
         *
         * Check safari browser and apply fix class
         **/

        if (
            navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1
        ) {
            document.querySelector('body').classList.add('safari-browser');
        }
    })();
});
