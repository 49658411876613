// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/js/i18n/bg.js';

import './web/main-scripts';
import './web/order-scripts';
import './web/form-validate-scripts';
import './web/favourites-scripts';
import './web/facebook-scripts';
import LiveChatScripts from './web/live-chat-scripts';

import AlertsScripts from './web/alerts-scripts.js';
import ProductIndexScripts from './web/product-index-scripts';

//import 'cookieconsent/build/cookieconsent.min.js';
import Cookies from './web/cookies';
import BoricaScripts from './web/borica-scripts';

Rails.start();
Turbolinks.start();
//ActiveStorage.start();

// browser back button issue
document.addEventListener('turbolinks:before-cache', function () {
    AlertsScripts.clearAlertsBrowserBack();
    Cookies.destroy();
    LiveChatScripts.destroy();
});

document.addEventListener('turbolinks:load', function () {
	BoricaScripts.init();
    AlertsScripts.init();
    ProductIndexScripts.init();
    Cookies.init();
    LiveChatScripts.init();
});
