import Alerts from './alerts-scripts';

/**
 * @created by Zheko Hristov on 17/03/2022
 *
 * Favourites site scripts
 **/

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        favouritesInit();

        const addToFavouritesButton = document.querySelector('.js-add-to-favourites');
        const removeFromFavouritesButton = document.querySelector('.js-remove-from-favourites');
        const detailsImageFavouritesIcon = document.querySelector(
            '.product__container--image-outer .fa-heart'
        );

        let productId = null;

        if (addToFavouritesButton) {
            productId = parseInt(addToFavouritesButton.dataset.productId);
            /**
             * @created by Zheko Hristov on 19/08/2021
             * @updated by Zheko Hristov on 15/10/2021
             *
             * Add to favourites scripts
             **/

            addToFavouritesButton.addEventListener('click', function () {
                //let productId = addToFavouritesButton.dataset.productId;
                let url = '/favourites';

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: {
                        id: productId,
                    },
                    dataType: 'json',
                })
                    .done(function (json) {
                        successAddToFavourites(json.counter, json.message);
                    })
                    .fail(function (xhr, status, error) {
                        let response = xhr.responseJSON || {
                            errors: ['Възникна грешка при добавянето в любими'],
                        };
                        failAddToFavourites(response.errors[0]);
                    });

                // request code here
                // ....
                // ....

                // on success code of the request
                // ....
                // successAddToFavourites();

                // on failure code of the request
                // ....
                // failAddToFavourites();

                // prevent fast clicking on remove from favourites button
                removeFromFavouritesButton.style.pointerEvents = 'none';
                setTimeout(function () {
                    removeFromFavouritesButton.style.pointerEvents = 'auto';
                }, 1000);
            });

            // success add to favourites function
            function successAddToFavourites(counter, message) {
                addToFavouritesButton.classList.remove('showed');
                removeFromFavouritesButton.classList.add('showed');
                detailsImageFavouritesIcon.classList.add('showed');
                detailsImageFavouritesIcon.classList.remove('show-out');
                detailsImageFavouritesIcon.classList.add('show-in');

                message ||= 'Добавихте продукт в любими';
                Alerts.renderAlert(message, 'success');
                // update favourite items count with the new updated value
                updateFavouritesCounter(counter);
            }

            // fail to add to favourites function
            function failAddToFavourites(message) {
                Alerts.renderAlert(message, 'error');
            }

            /**
             * @created by Zheko Hristov on 19/08/2021
             * @updated by Zheko Hristov on 15/10/2021
             *
             * Remove from favourites scripts
             **/

            removeFromFavouritesButton.addEventListener('click', function () {
                //let productId = removeFromFavouritesButton.dataset.productId;
                let url = '/favourites';

                $.ajax({
                    type: 'DELETE',
                    url: url,
                    data: {
                        id: productId,
                    },
                    dataType: 'json',
                })
                    .done(function (json) {
                        successRemoveFromFavourites(json.counter, json.message);
                    })
                    .fail(function (xhr, status, error) {
                        let response = xhr.responseJSON || {
                            errors: ['Възникна грешка при премахването от любими'],
                        };
                        failRemoveFromFavourites(response.errors[0]);
                    });

                // prevent fast clicking on add to favourites button
                addToFavouritesButton.style.pointerEvents = 'none';
                setTimeout(function () {
                    addToFavouritesButton.style.pointerEvents = 'auto';
                }, 1000);
            });

            // success remove from favourites function
            function successRemoveFromFavourites(counter, message) {
                addToFavouritesButton.classList.add('showed');
                removeFromFavouritesButton.classList.remove('showed');
                detailsImageFavouritesIcon.classList.remove('show-in');
                detailsImageFavouritesIcon.classList.add('show-out');
                Alerts.renderAlert(message, 'info');
                // update favourite items count with the new updated value
                updateFavouritesCounter(counter);
            }

            // fail to remove from favourites function
            function failRemoveFromFavourites(message) {
                Alerts.renderAlert(message, 'error');
            }
        }

        const markFavourites = function markFavourites() {
            let url = '/favourites/list';
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'json',
            })
                .done(function (json) {
                    let productIds = json.ids;
                    updateFavouritesCounter(productIds.length);

                    if (productId !== null) {
                        // if (productIds.includes(parseInt(productId))) {
                        //     removeFromFavouritesButton.classList.add('showed');
                        // } else {
                        //     addToFavouritesButton.classList.add('showed');
                        // }

                        // Array.prototype.includes() is not supported in IE
                        // for this redevelop the above block section with for loop
                        for (let i = 0; i < productIds.length; i++) {
                            if (productId === productIds[i]) {
                                addToFavouritesButton.classList.remove('showed');
                                removeFromFavouritesButton.classList.add('showed');
                                break;
                            }
                        }
                    }

                    productIds.forEach((id) => displayProductAsFavourite(id));
                })
                .fail(function (xhr, status, error) {
                    console.error(error);
                });
        };

        markFavourites();

        const displayProductAsFavourite = function displayProductAsFavourite(productId) {
            const elements = document.querySelectorAll(
                '.product-added-to-favourites-icon[data-product-id="' + productId + '"]'
            );

            // elements.forEach((element) => element.classList.add('showed'));

            // NodeList.prototype.forEach() is not supported in IE
            // for this redevelop the above block section with for loop
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.add('showed');
            }
        };

        /**
         * @created by Zheko Hristov on 20/08/2021
         * @updated by Zheko Hristov on 25/08/2021
         *
         * Favourites counter scripts
         **/

        // function for getting the favourites data
        function favouritesInit() {
            // request for getting favouries count here
            // ....
            // ....
            // on success code of the request
            // ....
            // ....
            // updateFavouritesCounter(favourite items count);
            // markFavourites();
            // on failure code of the request
            // ....
            // console.log('error occured');
        }

        // function for updating favourites counter
        function updateFavouritesCounter(favouritesCount) {
            const headerFavouritesTextCounter = document.querySelector(
                '.header__container--favourites span'
            );
            let displayCounterText = '0';
            headerFavouritesTextCounter.style.display = 'none';
            if (favouritesCount > 0) {
                headerFavouritesTextCounter.style.display = 'flex';
                displayCounterText = favouritesCount;
                if (favouritesCount > 99) {
                    displayCounterText = '99+';
                }
            }

            headerFavouritesTextCounter.innerText = displayCounterText;
        }
    })();
});

document.addEventListener('turbolinks:before-cache', function () {
    // clear the products marked as favourites
    const elements = document.querySelectorAll('.product-added-to-favourites-icon');
    // elements.forEach((element) => element.classList.remove('showed'));

    // NodeList.prototype.forEach() is not supported in IE
    // for this redevelop the above block section with for loop
    for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('showed');
    }
});
