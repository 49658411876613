import intlTelInput from 'intl-tel-input';
import intlTelInputBgLocale from "intl-tel-input/build/js/i18n/bg"; // Bulgarian

/**
 * @created by Zheko Hristov on 17/08/2021
 * @last-updated by Zheko Hristov on 12/08/2024
 *
 * Order form validate scripts
 **/

import Alerts from './alerts-scripts';

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        // basic regex patterns for input and textarea validation, based on the field id
        const regexInputPatterns = {
            user_name: /^[a-zа-я]{1}[a-zа-я\s-]*$/i,
            user_telephone: /^[\+]?\d{10,}$/,
            user_email: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            // user_city: /^[a-zа-я]{1}[a-zа-я\s]+$/i,
            user_city: /\S+/,
            user_address: /\S+/,
            user_delivery_price: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
            user_cardholder_name: /^[a-zA-Z\s]+$/i,
            user_no_validate: '',
        };

        // validation function with regex
        // for toggling 'field--valid' and 'field--valid' classes on the fields
        function validateField(field, regex) {
            // if there is econt validation do not use our validation
            if (field.classList.contains('container-with-econt-validation') || regex.length === 0) {
                return;
            }
            if (regex.test(field.value)) {
                field.classList.remove('field--invalid');
                field.classList.add('field--valid');
            } else {
                field.classList.remove('field--valid');
                field.classList.add('field--invalid');
            }
        }

        // telephone validation
        function bindPhoneNumber() {
            const input = document.querySelector("#user_telephone");
            if (input) {
                const phoneCodeInput = document.querySelector("#user_telephone_country_code");
                const iti = intlTelInput(input, {
                    i18n: intlTelInputBgLocale,
                    initialCountry: "bg",
                    separateDialCode: true,
                    utilsScript: "/js/intl-tel-input/utils.js",
                    autoPlaceholder: 'off',
                    nationalMode: false,
                    hiddenInput: function(telInputName) {
                        return {
                        phone: "order[client_phone]"
                        };
                    }
                });

                // here, the index maps to the error code returned from getValidationError - see readme
                const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

                function setPhoneCountryCode(){
                    let phoneCode = iti.selectedCountryData.dialCode;
                    phoneCodeInput.value = phoneCode;
                }

                // Validate the input
                input.addEventListener('input', function(){
                    setPhoneCountryCode();

                    input.classList.remove("field--invalid");
                    input.classList.remove("field--valid");

                    if (!input.value.trim()) {
                        input.classList.add("field--invalid");
                        input.classList.remove("field--valid");
                        

                    //  console.log("Required");
                    } else if (iti.isValidNumber()) {
                        input.classList.remove("field--invalid");
                        input.classList.add("field--valid");

                    } else {
                        // const errorCode = iti.getValidationError();
                        // const msg = errorMap[errorCode] || "Invalid number";
                        // console.log(msg);
                        input.classList.add("field--invalid");
                        input.classList.remove("field--valid");
                    }


                });

                input.addEventListener("countrychange", function() {
                    setPhoneCountryCode();
                });
            }
        }

        bindPhoneNumber();

        // form submit button
        const orderFormSubmitButton = document.querySelector('.form-input__button--form-submit');

        // inputs and textareas checks
        const validateInputElements = document.querySelectorAll('.input--validate');
        const validateInputElementsLength = validateInputElements.length;
        for (let i = 0; i < validateInputElementsLength; i++) {
            validateInputElements[i].addEventListener('input', function (e) {
                validateField(e.target, regexInputPatterns[e.target.dataset.validateType]);
            });
            validateInputElements[i].addEventListener('input', enableaDisableSubmitButton);
        }

        // checkboxes checks
        const checkboxInputs = document.querySelectorAll(
            '.form-input__container--right.checkbox__container input[type=checkbox]'
        );
        const checkboxInputsLength = checkboxInputs.length;
        for (let i = 0; i < checkboxInputsLength; i++) {
            checkboxInputs[i].addEventListener('change', enableaDisableSubmitButton);
        }

        bindEcontEvents();

        function disableSubmitButton() {
            orderFormSubmitButton.classList.add('disabled');
            orderFormSubmitButton.setAttribute('tabindex', '-1');
            orderFormSubmitButton.setAttribute('disabled', '');
        }

        function enableSubmitButton() {
            orderFormSubmitButton.classList.remove('disabled');
            orderFormSubmitButton.setAttribute('tabindex', '0');
            orderFormSubmitButton.removeAttribute('disabled');
        }

        // econt validation function
        function bindEcontEvents() {
            // autofocus the search field on click
            $(document).on('focus', '.select2-selection', function (e) {
                let element = document.querySelector('.select2-search__field');
                if (element !== undefined && element !== null) {
                    element.focus();
                }
            });

            const form = $('#new-order');
            const autocompleteOfficeCity = $('#order-econt-office-city-autocomplete');
            const autocompleteOffice = $('#order-econt-office-autocomplete');
            const autocompleteAddressCity = $('#order-econt-address-city-autocomplete');
            const econtDeliveryAddressInput = $('#order-econt-delivery-address');
            const deliveryMethodSelect = $('.order-delivery-method');
            const deliveryPriceInfo = $('#order-delivery-price-info');
            const deliveryPriceInput = $('#order-delivery-price');
            const paymentMethodOptions = $('.order-payment-method');
            const orderTotalPriceInfo = $('#order-total-price-info');
            const orderTotalPriceInfoContainer = $('#order-total-price-info-container');

            let selectedDeliveryMethod = $('.order-delivery-method:checked').val();
            let selectedEcontOfficeCityId = autocompleteOfficeCity.val();
            let selectedEcontAddressCityId = autocompleteAddressCity.val();
            let econtDeliveryAddress = econtDeliveryAddressInput.val();
            let selectedPaymentMethod = $('.order-payment-method:checked').data('code');

            selectDeliveryMethod();
            // select method for delivery function
            function selectDeliveryMethod() {
                $('.form-input__container--outer[data-delivery-method]').addClass('hidden');
                $(
                    '.form-input__container--outer[data-delivery-method="' +
                        selectedDeliveryMethod +
                        '"]'
                ).removeClass('hidden');

                $('input[data-delivery-method]').attr('disabled', true);
                $('select[data-delivery-method]').attr('disabled', true);

                $('input[data-delivery-method="' + selectedDeliveryMethod + '"]').attr(
                    'disabled',
                    false
                );
                $('select[data-delivery-method="' + selectedDeliveryMethod + '"]').attr(
                    'disabled',
                    false
                );

                updatePaymentMethodOptions();
            }

            function updatePaymentMethodOptions() {
                // Disable and enable payment methods depending on the selected delivery method
                let allowedMethods = ['cash_on_delivery', 'card'];

                if (selectedDeliveryMethod == 'international_dhl') {
                    allowedMethods = ['bank_transfer', 'card'];
                    allowedMethods = ['bank_transfer'];
                } else {
                    allowedMethods = ['cash_on_delivery', 'card'];
                }

                for (var i = paymentMethodOptions.length - 1; i >= 0; i--) {
                    if (allowedMethods.indexOf(paymentMethodOptions[i].dataset.code) >= 0) {
                        paymentMethodOptions[i].disabled = false;
                        paymentMethodOptions[i].parentElement.classList.remove('hidden');
                    } else {
                        paymentMethodOptions[i].checked = false;
                        paymentMethodOptions[i].disabled = true;
                        paymentMethodOptions[i].parentElement.classList.add('hidden');
                    }
                }

                selectedPaymentMethod = $('.order-payment-method:checked').data('code');

                // select default payment method if there is none
                if (checkIfBlank(selectedPaymentMethod) && allowedMethods.length > 0) {
                    selectedPaymentMethod = allowedMethods[0];
                    $('.order-payment-method[data-code="' + selectedPaymentMethod + '"]').prop(
                        'checked',
                        true
                    );
                }
            }

            // upfate statuses based on selected payment method
            function updateInputStatusOnPaymentMethodChange(paymentCode) {
                const paymentByCardContainer = $('.js-payment-by-card-container');
                const paymentByCardContainerInput = paymentByCardContainer.find('input[name="order[cardholder_name]"]');
                if (paymentCode === 'cash_on_delivery') {
                    paymentByCardContainer.addClass('container--hidden');
                    paymentByCardContainerInput.attr('disabled', true);
                } else if (paymentCode === 'card'){
                    paymentByCardContainer.removeClass('container--hidden');
                    paymentByCardContainerInput.attr('disabled', false);
                }
            }

            paymentMethodOptions.each(function () {
                const paymentMethodContainer = $(this);
                if (paymentMethodContainer.is(':checked')) {
                    updateInputStatusOnPaymentMethodChange(paymentMethodContainer.attr('data-code'));
                    return false;
                }
            });

            paymentMethodOptions.on('change', function () {
                console.log('payment method changed');
                updateInputStatusOnPaymentMethodChange($(this).attr('data-code'));
                calculateDeliveryPrice();
            });

            deliveryMethodSelect.on('change', function () {
                selectedDeliveryMethod = $(this).val();
                selectDeliveryMethod();
                calculateDeliveryPrice();
                enableaDisableSubmitButton();
            });

            function checkIfBlank(val) {
                return val == undefined || val == null || val.length < 1;
            }

            // Autocomplete city for econt office
            autocompleteOfficeCity.select2({
                minimumInputLength: 0,
                language: 'bg',
                ajax: {
                    delay: 600,
                    data: function (params) {
                        var query = {
                            term: params.term,
                            page: params.page || 1,
                            has_active_offices: true,
                        };

                        return query;
                    },
                    url: function (params) {
                        // return autocomplete.data('url');
                        return '/econt/cities';
                    },
                },
                width: 'resolve',
            });

            // Econt office autocomplete
            autocompleteOffice.select2({
                minimumInputLength: 0,
                language: 'bg',
                minimumResultsForSearch: 15,
                ajax: {
                    delay: 600,
                    data: function (params) {
                        var query = {
                            term: params.term,
                            page: params.page || 1,
                            city_id: selectedEcontOfficeCityId,
                        };

                        return query;
                    },
                    url: function (params) {
                        // return autocomplete.data('url');
                        return '/econt/offices';
                    },
                },
                width: 'resolve',
            });

            autocompleteOfficeCity.on('select2:select', function (e) {
                let data = e.params.data;

                if (selectedEcontOfficeCityId != data.id) {
                    // reset the office select
                    autocompleteOffice.val(null).trigger('change');
                }

                selectedEcontOfficeCityId = data.id;

                if (checkIfBlank(selectedEcontOfficeCityId)) {
                    autocompleteOfficeCity.addClass('field--invalid');
                    autocompleteOfficeCity.removeClass('field--valid');
                } else {
                    autocompleteOfficeCity.removeClass('field--invalid');
                    autocompleteOfficeCity.addClass('field--valid');
                }
            });

            autocompleteOffice.on('select2:select', function (e) {
                let data = e.params.data;

                if (checkIfBlank(data.id)) {
                    autocompleteOffice.addClass('field--invalid');
                    autocompleteOffice.removeClass('field--valid');
                } else {
                    autocompleteOffice.removeClass('field--invalid');
                    autocompleteOffice.addClass('field--valid');
                }

                calculateDeliveryPrice();

                enableaDisableSubmitButton();
            });

            // Econt city autocomplete - delivery to address option
            autocompleteAddressCity.select2({
                minimumInputLength: 0,
                language: 'bg',
                ajax: {
                    delay: 600,
                    data: function (params) {
                        var query = {
                            term: params.term,
                            page: params.page || 1,
                            has_active_streets: true,
                        };

                        return query;
                    },
                    url: function (params) {
                        // return autocomplete.data('url');
                        return '/econt/cities';
                    },
                },
                width: 'resolve',
            });

            autocompleteAddressCity.on('select2:select', function (e) {
                let data = e.params.data;
                if (selectedEcontAddressCityId != data.id) {
                    selectedEcontAddressCityId = data.id;
                    validateAddress();
                }

                if (checkIfBlank(selectedEcontAddressCityId)) {
                    autocompleteAddressCity.addClass('field--invalid');
                    autocompleteAddressCity.removeClass('field--valid');
                } else {
                    autocompleteAddressCity.removeClass('field--invalid');
                    autocompleteAddressCity.addClass('field--valid');
                }

                enableaDisableSubmitButton();
            });

            econtDeliveryAddressInput.on('input', function () {
                if ($(this).val() != econtDeliveryAddress) {
                    econtDeliveryAddress = $(this).val();
                    // disable the button on start writing
                    disableSubmitButton();
                    // orderFormSubmitButton.classList.add('disabled');
                    // orderFormSubmitButton.setAttribute('tabindex', '-1');
                    // orderFormSubmitButton.setAttribute('disabled', '');
                    debounce(validateAddress, 1000);
                }
            });

            function validateAddress() {
                if (checkIfBlank(econtDeliveryAddress)) {
                    updateEcontDeliveryAddress({});
                    calculateDeliveryPrice();
                }

                if (
                    checkIfBlank(econtDeliveryAddress) ||
                    checkIfBlank(selectedEcontAddressCityId)
                ) {
                    return;
                }

                let url = '/econt/address/validate';

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: {
                        city_id: selectedEcontAddressCityId,
                        address: econtDeliveryAddress,
                    },
                    dataType: 'json',
                })
                    .done(function (json) {
                        updateEcontDeliveryAddress(json.address);
                        econtDeliveryAddressInput.removeClass('field--invalid');
                        econtDeliveryAddressInput.addClass('field--valid');
                        calculateDeliveryPrice();
                        enableaDisableSubmitButton();
                    })
                    .fail(function (xhr, status, error) {
                        econtDeliveryAddressInput.addClass('field--invalid');
                        econtDeliveryAddressInput.removeClass('field--valid');
                        updateEcontDeliveryAddress({ fullAddress: econtDeliveryAddress });

                        let response = xhr.responseJSON || {
                            error: 'Възникна грешка при валидацията на адреса',
                        };

                        Alerts.renderAlert(response.error, 'error');
                        calculateDeliveryPrice();
                    });
            }

            function calculateDeliveryPrice() {
                disableSubmitButton();

                if (selectedDeliveryMethod == 'international_dhl') {
                    deliveryPriceInfo.html(
                        'Според тарифата на DHL. <a href="https://mydhl.express.dhl/bg/bg/home.html#/getQuoteTab" target="_blank" noreferrer>Калкулатор</a>'
                    );
                    deliveryPriceInput.val(null);
                    // unlock the delivery price input for dhl method
                    deliveryPriceInput.removeClass('field--invalid').addClass('field--valid');
                    orderTotalPriceInfo.html('');
                    orderTotalPriceInfoContainer.addClass('hidden');
                    enableaDisableSubmitButton();
                    return;
                }

                let formData = form.serialize();

                $.ajax({
                    type: 'POST',
                    url: '/econt/delivery-price/calculate',
                    data: formData, // serializes the form's elements.
                })
                    .done(function (json) {
                        deliveryPriceInfo.html(json.price_text);
                        deliveryPriceInput.val(json.price);
                        // trigger the input event via plain js, because of the event is attached via plain js
                        deliveryPriceInput[0].dispatchEvent(new Event('input', { bubbles: true }));

                        orderTotalPriceInfo.html(json.total_price_text);
                        if (!checkIfBlank(json.total_price_text)) {
                            orderTotalPriceInfoContainer.removeClass('hidden');
                        }

                        enableaDisableSubmitButton();
                    })
                    .fail(function (xhr, status, error) {
                        console.log(xhr);
                        console.log(error);

                        deliveryPriceInfo.html('Според тарифата на Еконт');
                        deliveryPriceInput.val(null);
                        // trigger the input event via plain js, because of the event is attached via plain js
                        deliveryPriceInput[0].dispatchEvent(new Event('input', { bubbles: true }));
                        orderTotalPriceInfo.html('');
                        orderTotalPriceInfoContainer.addClass('hidden');

                        Alerts.renderAlert(
                            'Съжаляваме, възникна грешка при изчисляването на доставката',
                            'error'
                        );
                    });
            }

            const deliveryAddressStreetInput = $('#order-econt-delivery-address-street');
            const deliveryAddressStreetNumInput = $('#order-econt-delivery-address-street-num');
            const deliveryAddressQuarterInput = $('#order-econt-delivery-address-quarter');
            const deliveryAddressOtherInput = $('#order-econt-delivery-address-other');

            function updateEcontDeliveryAddress(address) {
                econtDeliveryAddressInput.val(address.fullAddress);
                deliveryAddressStreetInput.val(address.street);
                deliveryAddressStreetNumInput.val(address.num);
                deliveryAddressQuarterInput.val(address.quarter);
                deliveryAddressOtherInput.val(address.other);
            }
        }

        // function for enable/disable submit button, based on the validations and checkboxes
        function enableaDisableSubmitButton() {
            const activeInputFields = document.querySelectorAll('.input--validate:not([disabled]');
            const activeInputFieldsLength = activeInputFields.length;
            const validInputFields = document.querySelectorAll(
                '.input--validate.field--valid:not([disabled]'
            );
            const validInputFieldsLength = validInputFields.length;
            const checkedCheckboxes = document.querySelectorAll(
                '.form-input__container--right.checkbox__container input[type=checkbox]:checked'
            );
            const checkedCheckboxesLength = checkedCheckboxes.length;
            // const activeSelectFields = document.querySelectorAll('.input--validate:not([disabled]')

            if (
                validInputFieldsLength === activeInputFieldsLength &&
                checkedCheckboxesLength === checkboxInputsLength
            ) {
                // orderFormSubmitButton.classList.remove('disabled');
                // orderFormSubmitButton.setAttribute('tabindex', '0');
                // orderFormSubmitButton.removeAttribute('disabled');
                enableSubmitButton();
            } else {
                // orderFormSubmitButton.classList.add('disabled');
                // orderFormSubmitButton.setAttribute('tabindex', '-1');
                // orderFormSubmitButton.setAttribute('disabled', '');
                disableSubmitButton();
            }
        }

        // simple deboucnce function
        let debounceTimer;

        function debounce(callback, time) {
            window.clearTimeout(debounceTimer);
            debounceTimer = window.setTimeout(callback, time);
        }
    })();
});
